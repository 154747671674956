(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/items-menu/assets/javascripts/items-menu.js') >= 0) return;  svs.modules.push('/components/ui/items-menu/assets/javascripts/items-menu.js');

'use strict';

svs.ui = svs.ui || {};
svs.ui.itemsMenu = svs.ui.itemsMenu || {};
svs.ui.ItemsMenu = class ItemsMenu {
  constructor(options) {
    const self = this;
    this.isHover = false;
    this.log = svs.core.log.getLogger('ui:items-menu');

    if (options.items && options.items.length && options.el) {
      self.renderItems(options.el, options, () => {
        self.initItemsMenu(options, () => {
          self.setFaders();
          self.setUpScrollHelpers(self.options.useScrollFaders, self.options.useScrollHelpers, self.options.scrollIcons, self.options.showScrollbar);
          self.listenToScroll();
          self.listenToClick();
          self.setUpResizeListener();
          self.scrollToSelectedItem();
          self.listenToHover();
        });
      });
    } else {
      this.initItemsMenu(options, () => {
        self.setFaders();
        self.setUpScrollHelpers(self.options.useScrollFaders, self.options.useScrollHelpers, self.options.scrollIcons, self.options.showScrollbar);
        self.listenToScroll();
        self.listenToClick();
        self.setUpResizeListener();
        self.scrollToSelectedItem();
        self.listenToHover();
      });
    }
  }

  initItemsMenu(options, callback) {
    const $el = options && options.el ? options.el : undefined;
    let navEl;
    if ($el) {
      if ($el instanceof Element) {
        if ($el.classList.contains('js-items-menu-wrapper')) {
          navEl = $el;
        } else {
          navEl = $el.querySelector('.js-items-menu-wrapper');
        }
      } else if (document.querySelector(".js-items-menu-wrapper.".concat($el))) {
        navEl = document.querySelector(".js-items-menu-wrapper.".concat($el));
      } else {
        navEl = document.querySelector(".".concat($el)).querySelector(".js-items-menu-wrapper");
      }
      if (window.ResizeObserver !== undefined) {
        const resizeObserver = new ResizeObserver(() => {
          this.setFaders();
          this.setUpScrollHelpers(options.useScrollFaders, options.useScrollHelpers, options.scrollIcons, options.showScrollbar);
        });
        resizeObserver.observe(navEl.querySelector('.items-menu-nav'));
      }
      const menuEl = navEl.querySelector('.js-items-menu-holder');
      this.options = {
        menuEl,
        useScrollHelpers: options && options.useScrollHelpers ? options.useScrollHelpers : false,
        useScrollFaders: options && options.useScrollFaders ? options.useScrollFaders : false,
        useScrollFadersOnHover: options && options.useScrollFadersOnHover ? options.useScrollFadersOnHover : false,
        scrollToSelected: options && options.scrollToSelectedItem ? options.scrollToSelectedItem : true,
        scrollIcons: options && options.scrollIcons ? options.scrollIcons : undefined,
        showScrollbar: options && options.showScrollbar ? options.showScrollbar : false,
        scrollWidth: navEl.querySelector('.js-items-menu-nav').scrollWidth,
        menuWidth: navEl.getBoundingClientRect().width,
        leftDimmer: navEl.querySelector('.js-items-menu-dimmer-left'),
        rightDimmer: navEl.querySelector('.js-items-menu-dimmer-right'),
        itemList: navEl.querySelectorAll('.js-items-menu-item'),
        firstItem: navEl.querySelectorAll('.js-items-menu-item')[0],
        lastItem: navEl.querySelectorAll('.js-items-menu-item')[navEl.querySelectorAll('.js-items-menu-item').length - 1],
        allowToggle: options && options.allowToggle ? options.allowToggle : false,
        clickCallback: options && options.clickCallback ? options.clickCallback : false,
        inverted: options && options.inverted ? options.inverted : false,
        branding: options && options.branding ? options.branding : false,
        items: options && options.items ? options.items : [],
        $el: options && options.el ? options.el : undefined,
        navEl,
        throttled: false,
        widestItem: undefined,
        itemsType: options && options.itemsType ? options.itemsType : 'ball_item'
      };
      callback();
    } else {
      this.log.error('No el found in options object.');
    }
  }

  renderItems($el, options, callback) {
    if (options.el && options.items && options.items.length > 0) {
      const items = [];
      const itemsType = options.itemsType ? options.itemsType : 'ball_item';
      for (let i = 0; i < options.items.length; i++) {
        options.items[i].itemIndex = i;
        items.push(svs.ui.items_menu.partials[itemsType](options.items[i]));
      }
      const itemsMenu = svs.ui.items_menu.partials.items_menu({
        class: options.class,
        centered: options.centered,
        branding: options.branding,
        inverted: options.inverted,
        items
      });
      if ($el instanceof Element) {
        $el.innerHTML = itemsMenu;
      } else {
        document.querySelector(".".concat($el)).innerHTML = itemsMenu;
      }
      callback();
    }
  }

  setUpScrollHelpers(useFaders, useScrollHelpers, scrollIcons, showScrollbar) {
    if (useFaders) {
      this.log.debug('scrollWidth: ', this.options.scrollWidth, 'menuWidth: ', this.options.menuWidth);
      if (useScrollHelpers) {
        const leftIcon = scrollIcons && scrollIcons.left ? scrollIcons.left : 'icon-menu-left';
        const rightIcon = scrollIcons && scrollIcons.right ? scrollIcons.right : 'icon-menu-right';
        this.options.leftDimmer.innerHTML = "<span class=\"items-menu-dimmer-icon items-menu-dimmer-icon-left".concat(this.options.menuEl.clientHeight < 50 ? ' items-menu-dimmer-icon-small' : '', "\">\n          <i class=\"icon icon-default icon-100 ").concat(leftIcon, "\"></i></span>");
        this.options.rightDimmer.innerHTML = "<span class=\"items-menu-dimmer-icon items-menu-dimmer-icon-right".concat(this.options.menuEl.clientHeight < 50 ? ' items-menu-dimmer-icon-small' : '', "\">\n          <i class=\"icon icon-default icon-100 ").concat(rightIcon, "\"></i></span>");
      }
    }
    if (!showScrollbar) {
      const menuWrapper = this.options.navEl;
      const scrollbarHeight = menuWrapper.clientHeight - menuWrapper.querySelector('.js-items-menu-nav').clientHeight;
      menuWrapper.style.overflow = 'hidden';
      menuWrapper.style.height = "".concat(menuWrapper.clientHeight - scrollbarHeight, "px");
    }
  }

  setFaders() {
    if (this.options.useScrollFaders) {
      if (!this.options.firstItem.classList.contains('items-menu-link-item-absolute')) {
        this.options.menuEl.webkitOverflowScrolling = 'unset';
        if (this.options.menuEl.scrollLeft < this.options.firstItem.clientWidth / 4) {
          this.options.leftDimmer.style.transition = 'transform ease-in-out 400ms';
          this.options.leftDimmer.style.transform = 'scaleX(0)';
        } else {
          if (!this.options.useScrollFadersOnHover || this.isHover && this.options.useScrollFadersOnHover) {
            this.options.leftDimmer.style.transition = 'transform ease-in-out 400ms';
            this.options.leftDimmer.style.transform = 'scaleX(1)';
          }
        }
      }
      if (this.options.menuEl.scrollLeft + this.options.menuEl.clientWidth >= this.options.menuEl.scrollWidth - this.options.lastItem.clientWidth / 4) {
        this.options.rightDimmer.style.transform = 'scaleX(0)';
      } else {
        if (!this.options.useScrollFadersOnHover || this.options.useScrollFadersOnHover && this.isHover) {
          this.options.rightDimmer.style.transform = 'scaleX(1)';
        }
      }
    }
    if (!this.options.firstItem.classList.contains('items-menu-link-item-absolute')) {
      this.options.menuEl.classList.add('items-menu-overflow-touch');
    }
  }

  listenToScroll() {
    const self = this;
    this.options.menuEl.addEventListener('scroll', () => {
      self.setFaders();
    });
  }
  listenToHover() {
    if (this.options.useScrollFadersOnHover) {
      this.options.navEl.addEventListener('mouseenter', () => {
        this.isHover = true;
        this.setFaders();
      });
      this.options.navEl.addEventListener('mouseleave', () => {
        this.isHover = false;
        this.setFaders();
      });
    }
  }

  listenToClick() {
    const self = this;
    if (!this.options.widestItem) {
      const items = this.options.itemList;
      for (let i = 0; i < items.length; i++) {
        this.options.widestItem = this.options.widestItem ? items[i].clientWidth > this.options.widestItem ? items[i].clientWidth : this.options.widestItem : items[i].clientWidth;
      }
    }
    const scrollBy = this.options.widestItem ? this.options.widestItem + 42 : 100; 

    this.options.leftDimmer.addEventListener('click', e => {
      e.preventDefault();
      if (self.options.menuEl.scrollBy === undefined) {
        const scrollLeft = self.options.menuEl.scrollLeft + scrollBy;
        self.options.menuEl.scrollLeft = -scrollLeft;
      } else {
        self.options.menuEl.scrollBy({
          top: 0,
          left: -scrollBy,
          behavior: 'smooth'
        });
      }
      return false;
    });
    this.options.rightDimmer.addEventListener('click', e => {
      e.preventDefault();
      if (self.options.menuEl.scrollBy === undefined) {
        const scrollLeft = self.options.menuEl.scrollLeft + scrollBy;
        self.options.menuEl.scrollLeft = scrollLeft;
      } else {
        self.options.menuEl.scrollBy({
          top: 0,
          left: scrollBy,
          behavior: 'smooth'
        });
      }
      return false;
    });

    for (let i = 0; i < this.options.itemList.length; i++) {
      this.options.itemList[i].addEventListener('click', e => {
        const evt = e;

        if (!self.options.throttled) {
          self.options.throttled = true;
          if (!evt.currentTarget.classList.contains('items-menu-active')) {
            const selectedItems = evt.currentTarget.parentElement.getElementsByClassName('items-menu-active');
            for (let i = 0; i < selectedItems.length; i++) {
              selectedItems[i].ariaSelected = 'false';
              selectedItems[i].classList.remove('items-menu-active');
            }
            evt.currentTarget.ariaSelected = 'true';
            evt.currentTarget.classList.add('items-menu-active');
          } else if (self.options.allowToggle && evt.currentTarget.classList.contains('items-menu-active')) {
            evt.currentTarget.ariaSelected = 'false';
            evt.currentTarget.classList.remove('items-menu-active');
          }
          if (evt.currentTarget.dataset && evt.currentTarget.dataset.index && self.options.items[parseInt(evt.currentTarget.dataset.index, 10)] && self.options.items[evt.currentTarget.dataset.index].clickCallback) {
            evt.stopImmediatePropagation();
            self.options.items[evt.currentTarget.dataset.index].clickCallback(self.getItemAttributes(evt), evt);
          }

          if (self.options.clickCallback) {
            evt.stopImmediatePropagation();
            self.options.clickCallback(self.getItemAttributes(evt), evt);
          }
          if (self.options.scrollToSelected) {
            this.scrollToSelectedItem();
          }
          self.options.throttled = false;
        }
      });
    }
  }

  getItemAttributes(evt) {
    evt.preventDefault();
    const item = {};
    const attributes = evt.currentTarget.attributes;
    for (let i = 0; i < attributes.length; i++) {
      item[attributes[i].name] = attributes[i].value;
    }
    return item;
  }

  setUpResizeListener() {
    const self = this;
    window.addEventListener('resize', () => {
      if (!self.options.throttled) {
        self.setFaders();
        self.options.throttled = true;

        setTimeout(() => {
          self.options.throttled = false;
        }, 250);
      }
    });
  }

  scrollToSelectedItem() {
    if (this.options.scrollToSelected) {
      const activeEl = this.options.navEl.querySelector('.items-menu-active');
      if (activeEl && this.isNotInView(activeEl) && this.options.menuEl.scrollBy) {
        const activePos = activeEl.offsetLeft + activeEl.clientWidth + 12 + (this.options.useScrollFaders ? this.options.rightDimmer.clientWidth : 0) - this.options.menuEl.clientWidth;
        this.options.menuEl.scrollBy({
          top: 0,
          left: activePos,
          behavior: 'smooth'
        });
      }
    }
  }

  isNotInView(element) {
    const rect = element.getBoundingClientRect();
    const wrapperRect = this.options.navEl.getBoundingClientRect();
    const scrollAreaWidth = this.options.navEl.querySelector('.js-items-menu-holder').clientWidth;
    return rect.right >= scrollAreaWidth || rect.left <= wrapperRect.left;
  }

  setSelectedItemByIndex(index, scrollToSelected) {
    const items = this.options.navEl.querySelectorAll('.js-items-menu-item');
    for (let i = 0; i < items.length; i++) {
      if (index === i) {
        items[i].classList.add('items-menu-active');
      } else {
        items[i].classList.remove('items-menu-active');
      }
    }

    if (scrollToSelected) {
      this.scrollToSelectedItem();
    }
  }
};

 })(window);